import _forOwn from "lodash/forOwn";

// turns "STRING_LIKE_THIS" to "String like this"
export const stringToSentenceCase = (underscoredString = "") => {
  let spacedAndLowercased = underscoredString;
  if (spacedAndLowercased.includes("_"))
    spacedAndLowercased = underscoredString.replace(/_/g, " ");
  return (
    spacedAndLowercased.charAt(0).toUpperCase() +
    spacedAndLowercased.slice(1).toLowerCase()
  );
};

export const stepType = (step) => {
  if (step.ui_init_type) return step.ui_init_type;

  switch (step.__typename) {
    case "WorkflowTrigger":
      return "TRIGGER";
    case "WorkflowCondition":
      return "CONDITION";
    case "WorkflowAction":
      return "ACTION";
    case "WorkflowDelay":
      return "DELAY";
  }
  // if draft and no __typename
  if (step.trigger_type) return "TRIGGER";
  if (step.condition_operation) return "CONDITION";
  if (step.action_type) return "ACTION";
  if (step.delay_for || step.delay_until) return "DELAY";
  return null;
};

export const getTextOfValueInArr = (value, arr) => {
  const match = arr.filter((el) => {
    return el.value == value;
  });
  return match[0]?.text || value;
};

export const simplifySteps = (steps) => {
  let stepsCopy = JSON.parse(JSON.stringify([...steps]));

  stepsCopy.forEach((s) => {
    _forOwn(s, function(value, key) {
      delete s.workflow_step_id;
      delete s.ui_new;
      delete s.__typename;
      if (s[key] && Object.keys(s[key]).length) {
        _forOwn(s[key], function(valueInner, keyInner) {
          if (valueInner === null) {
            delete s[key][keyInner];
          }
        });
      }
      if (value === null) {
        delete s[key];
      }
    });

    if (s.condition_parameters) {
      s.condition_parameters.forEach((p) => {
        _forOwn(p, function(value, key) {
          if (value == null) {
            delete p[key];
          }
        });
      });
    }

    if (s.conditions) {
      s.conditions = simplifyCondition(s.conditions);

      s.conditions.forEach((cc) => {
        if (cc.conditions) {
          cc.conditions = simplifyCondition(cc.conditions, "inside cc");
        }
      });
    }
  });
  return stepsCopy;
};

export const simplifyCondition = (conditions) => {
  let conditionsCopy = JSON.parse(JSON.stringify(conditions));
  conditionsCopy.forEach((c) => {
    if (c.condition_parameters) {
      c.condition_parameters.forEach((p) => {
        _forOwn(p, function(value, key) {
          if (value === null) {
            delete p[key];
          }
        });
      });
    }
    _forOwn(c, function(value, key) {
      if (value === null) {
        delete c[key];
      }
    });
  });

  return conditionsCopy;
};

export const simplifySingleCondition = (condition) => {
  let conditionCopy = JSON.parse(JSON.stringify(condition));
  conditionCopy.condition_parameters.forEach((p) => {
    _forOwn(p, function(value, key) {
      if (value === null) {
        delete p[key];
      }
    });
  });

  // if (!conditionCopy.conditions?.length) {
  //   delete conditionCopy.conditions;
  // }
  delete conditionCopy.workflow_step_id;
  delete conditionCopy.__typename;
  delete conditionCopy.conditions;
  delete conditionCopy.key;
  return conditionCopy;
};

export const isNestedConditionPopulated = (conditionToValidate) => {
  let isValidAndPopulated = true;

  if (!conditionToValidate) {
    return false;
  }

  if (conditionToValidate.conditions?.length) {
    conditionToValidate.conditions.forEach((c) => {
      let hasValue1 = true,
        // hasValue2 = true,
        hasOperator = true,
        childrenConditionsHasValue = true;

      if (c?.condition_parameters?.length) {
        /**
         * If nested 1 level
         */
        hasValue1 =
          c.condition_parameters[0]?.variable ||
          c.condition_parameters[0]?.value ||
          c.condition_parameters[0]?.value === 0 ||
          c.condition_parameters[0]?.conditions ||
          c.condition_parameters[0]?.property_id ||
          c.condition_parameters[0]?.date_modifier;

        // hasValue2 =
        //   c.condition_parameters[1]?.variable ||
        //   c.condition_parameters[1]?.value ||
        //   c.condition_parameters[1]?.value === 0 ||
        //   c.condition_parameters[1]?.conditions ||
        //   c.condition_parameters[1]?.property_id ||
        //   c.condition_parameters[1]?.date_modifier;

        hasOperator = c.condition_operation;
      } else {
        /**
         * If has nested 2 levels
         */
        c?.conditions.forEach((c2) => {
          const c2HasValue1 =
            c2.condition_parameters[0].variable ||
            c2.condition_parameters[0].value ||
            c2.condition_parameters[0].value === 0 ||
            c2.condition_parameters[0].conditions ||
            c2.condition_parameters[0].property_id ||
            c2.condition_parameters[0].date_modifier;

          const c2HasOperator = c.condition_operation;

          const isShortcut =
            !c2.condition_parameters.length == 1 &&
            ["EMPTY", "NOT_EMPTY"].includes(c2HasOperator);

          if (!isShortcut && (!c2HasValue1 || !c2HasOperator)) {
            childrenConditionsHasValue = false;
          }
        });
      }

      if (!hasOperator || !hasValue1 || !childrenConditionsHasValue) {
        isValidAndPopulated = false;
      }
    });
  } else {
    /**
     * If simple
     */
    if (conditionToValidate.condition_parameters) {
      const hasValue1 =
        conditionToValidate.condition_parameters[0]?.variable ||
        conditionToValidate.condition_parameters[0]?.value ||
        conditionToValidate.condition_parameters[0]?.value === 0 ||
        conditionToValidate.condition_parameters[0]?.conditions ||
        conditionToValidate.condition_parameters[0]?.property_id ||
        conditionToValidate.condition_parameters[0]?.date_modifier;
      // const hasValue2 =
      //   conditionToValidate.condition_parameters[1]?.variable ||
      //   conditionToValidate.condition_parameters[1]?.value ||
      //   conditionToValidate.condition_parameters[1]?.value === 0 ||
      //   conditionToValidate.condition_parameters[1]?.conditions ||
      //   conditionToValidate.condition_parameters[1]?.property_id ||
      //   conditionToValidate.condition_parameters[1]?.date_modifier;
      const hasOperator = conditionToValidate.condition_operation;

      const isShortcut = ["EMPTY", "NOT_EMPTY"].includes(hasOperator);

      if (!isShortcut && (!hasValue1 || !hasOperator)) {
        isValidAndPopulated = false;
      }
    } else {
      isValidAndPopulated = false;
    }
  }

  return isValidAndPopulated ? !!conditionToValidate : null;
};

export const isNestedConditionPopulatedIncludingParam2 = (
  conditionToValidate
) => {
  if (!conditionToValidate) {
    return false;
  }

  let isValidAndPopulated = true;

  // Generic detection
  const hasValue = (param) =>
    param?.variable ||
    param?.value ||
    param?.value === 0 ||
    param?.value === "0" ||
    param?.conditions ||
    param?.property_id ||
    param?.date_modifier;

  if (conditionToValidate.conditions?.length) {
    // Check each nested condition
    conditionToValidate.conditions.forEach((c) => {
      let hasValue1 = true,
        hasValue2 = true,
        hasOperator = true,
        childrenConditionsHasValue = true;

      if (c?.condition_parameters?.length) {
        /**
         * If nested 1 level
         */
        hasOperator = c.condition_operation;
        hasValue1 = hasValue(c.condition_parameters[0]);
        hasValue2 =
          hasValue(c.condition_parameters[1]) &&
          (hasOperator !== "BETWEEN" || hasValue(c.condition_parameters[2]));

        // comboBoxIsEmpty = this.c.condition_parameters[1].value?.value === null;
      } else {
        /**
         * If has nested 2 levels
         */
        c?.conditions.forEach((c2) => {
          const c2HasOperator = c.condition_operation;
          const c2HasValue1 = hasValue(c2.condition_parameters[0]);
          const c2HasValue2 =
            hasValue(c2.condition_parameters[1]) &&
            (c2HasOperator !== "BETWEEN" ||
              hasValue(c2.condition_parameters[2]));

          // const c2comboBoxIsEmpty =
          //   this.c2.condition_parameters[1].value?.value === null;

          if (!c2HasValue1 || !c2HasValue2 || !c2HasOperator) {
            childrenConditionsHasValue = false;
          }
        });
      }

      if (
        !hasOperator ||
        !hasValue1 ||
        !hasValue2 ||
        !childrenConditionsHasValue
      ) {
        isValidAndPopulated = false;
      }

      if (!hasValue2 && ["EMPTY", "NOT_EMPTY"].includes(hasOperator)) {
        isValidAndPopulated = true;
      }
    });
  } else {
    /**
     * If simple
     */
    if (conditionToValidate.condition_parameters) {
      const hasOperator = conditionToValidate.condition_operation;
      const hasValue1 = hasValue(conditionToValidate.condition_parameters[0]);
      const hasValue2 =
        hasValue(conditionToValidate.condition_parameters[1]) &&
        (hasOperator !== "BETWEEN" ||
          hasValue(conditionToValidate.condition_parameters[2]));

      // const comboBoxIsEmpty =
      //   this.c.condition_parameters[1].value?.value === null;

      if (!hasValue1 || !hasValue2 || !hasOperator) {
        isValidAndPopulated = false;
      }
      if (!hasValue2 && ["EMPTY", "NOT_EMPTY"].includes(hasOperator)) {
        isValidAndPopulated = true;
      }
    } else {
      isValidAndPopulated = false;
    }
  }

  return isValidAndPopulated ? conditionToValidate : null;
};

export const getFreeIncludedActions = (plan) => {
  switch (plan) {
    case null:
    case "Optix Scale": {
      return 1000;
    }
    case "Optix Grow": {
      return 500;
    }
    case "Optix Pro": {
      return 200;
    }
    default:
      return 100;
  }
};

export const triggerRequiresAutomationsPlus = (trigger_type) => {
  return ["ALLOWANCE_CONSUMED"].includes(trigger_type);
};

export const actionRequiresAutomationsPlus = (action_type) => {
  return ["ADD_ALLOWANCE", "CHANGE_USER_STATUS", "ADD_INVOICE_ITEM"].includes(
    action_type
  );
};

export const checkIfStepRequiresPlus = (steps) => {
  let restricted = false;
  steps.forEach((s) => {
    if (s.trigger_type && triggerRequiresAutomationsPlus(s.trigger_type)) {
      restricted = true;
    }
    if (s.action_type && actionRequiresAutomationsPlus(s.action_type)) {
      restricted = true;
    }
  });
  return restricted;
};
