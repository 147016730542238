import {
  MUTATE_WIZARD_BREADCRUMB,
  MUTATE_WIZARD_ASSIGNMENT,
  MUTATE_WIZARD_CONDITIONS,
  MUTATE_WIZARD_LOADING,
  MUTATE_WIZARD_META,
  MUTATE_WIZARD_PROGRESS,
  MUTATE_WIZARD_CONFLICTS,
} from "@/constants";
import { DateTime } from "luxon";

export default {
  namespaced: true,
  state: {
    // Wizard common properties
    breadcrumb: "New assignment",
    isLoading: false,
    progress: 0,
    meta: {
      organization: null,
    },
    assignment: {
      start: null,
      end: null,
      no_end: true,
      price: null,
      notes: "",
      deposit_amount: 0,
      setup_fee: 0,
      override_tax_rate: false,
      tax_rate: null,
      override_inclusive_tax_rate: false,
      inclusive_tax_rate: null,
      price_interval: 1,
      paymentOption: "FREE",
      price_frequency: "MONTHLY",
      conditions: {},
    },
    conflicts: {
      message: false,
      payload: {},
    },
  },
  mutations: {
    [MUTATE_WIZARD_BREADCRUMB]: (state, breadcrumb) => {
      state.breadcrumb = breadcrumb;
    },
    [MUTATE_WIZARD_LOADING]: (state, value) => {
      state.isLoading = value;
    },
    [MUTATE_WIZARD_PROGRESS]: (state, value) => {
      state.progress = value;
    },
    [MUTATE_WIZARD_ASSIGNMENT]: (state, data) => {
      state.assignment = {
        ...state.assignment,
        ...data,
      };
    },
    [MUTATE_WIZARD_CONDITIONS]: (state, data) => {
      state.conditions = {
        ...state.conditions,
        ...data,
      };
    },
    [MUTATE_WIZARD_META]: (state, data) => {
      state.meta = {
        ...state.meta,
        ...data,
      };
    },
    [MUTATE_WIZARD_CONFLICTS]: (state, data) => {
      state.conflicts = {
        ...state.conflicts,
        ...data,
      };
    },
  },
  getters: {
    getProgress: (state) => state.progress,
    getIsLoading: (state) => state.isLoading,
    getOrganization: (state) => state.meta.organization,
    getAssignment: (state) => state.assignment,
    getConflicts: (state) => state.conflicts,
    getConditions: (state) => state.conditions,
    getContainerOptions: (state) => ({
      breadcrumb: [state.breadcrumb],
      loading: state.isLoading,
      headerStyle: {
        "padding-bottom": "24px !important",
      },
    }),
    getAssignmentInput: (state) => {
      const isNotFree = state.assignment.paymentOption !== "FREE";
      const input = {
        resource_id: state.assignment.resource_id,
        owner_account: {
          account_id: state.assignment.owner_account.account_id,
        },
        payer_account: {
          account_id: state.assignment.payer_account.account_id,
        },
        price_frequency:
          state.assignment.paymentOption === "REPEAT"
            ? state.assignment.price_frequency
            : null,
        price: isNotFree ? state.assignment.price : 0,
        price_interval:
          state.assignment.paymentOption === "REPEAT"
            ? state.assignment.price_interval
            : null,
        deposit_amount: isNotFree ? state.assignment.deposit_amount : 0,
        setup_fee: isNotFree ? state.assignment.setup_fee : 0,
        override_tax_rate: isNotFree
          ? state.assignment.override_tax_rate
          : false,
        tax_rate:
          isNotFree && state.assignment.override_tax_rate
            ? state.assignment.tax_rate
            : null,
        override_inclusive_tax_rate: isNotFree
          ? state.assignment.override_inclusive_tax_rate
          : false,
        inclusive_tax_rate:
          isNotFree && state.assignment.override_inclusive_tax_rate
            ? state.assignment.inclusive_tax_rate
            : null,
        no_end: state.assignment.no_end,
        notes: state.assignment.notes,
      };

      // Timezone
      let tz = state.assignment.resource.location.timezone;

      // Start and End hours
      let start_hour = "00:00";
      let end_hour = "00:00";
      if (
        state.assignment.conditions &&
        state.assignment.conditions.parts &&
        state.assignment.conditions.parts.start
      ) {
        start_hour = state.assignment.conditions.parts.start;
      }
      if (
        state.assignment.conditions &&
        state.assignment.conditions.parts &&
        state.assignment.conditions.parts.end
      ) {
        end_hour = state.assignment.conditions.parts.end;
      }
      if (end_hour == "00:00") {
        end_hour = "23:59";
      }

      // Generate first occurrence
      const first_occurrence_start_date_time = DateTime.fromISO(
        state.assignment.start + "T" + start_hour + ":00",
        {
          zone: tz,
        }
      );
      let first_occurrence_start_timestamp = parseInt(
        first_occurrence_start_date_time.toSeconds()
      );
      input.first_occurrence_start_timestamp = first_occurrence_start_timestamp;

      // Generate last occurrence
      let last_occurrence_end_timestamp = null;
      if (!state.assignment.no_end) {
        last_occurrence_end_timestamp = parseInt(
          DateTime.fromISO(state.assignment.end + "T" + end_hour + ":00", {
            zone: tz,
          }).toSeconds()
        );
        if (end_hour == "23:59") {
          last_occurrence_end_timestamp += 60;
        }
        input.last_occurrence_end_timestamp = last_occurrence_end_timestamp;
      }

      // Generate duration
      let end_of_day_timestamp = parseInt(
        DateTime.fromISO(state.assignment.start + "T00:00:00", {
          zone: tz,
        })
          .plus({ day: 1 })
          .toSeconds()
      );
      let duration_sec =
        end_of_day_timestamp - first_occurrence_start_timestamp;
      if (end_hour !== "00:00" && end_hour !== "23:59") {
        duration_sec = parseInt(
          DateTime.fromISO(state.assignment.start + "T" + end_hour, {
            zone: tz,
          }).toSeconds() - first_occurrence_start_timestamp
        );
      }

      if (first_occurrence_start_date_time.isInDST && duration_sec === 90000) {
        duration_sec = duration_sec - 3600;
      }

      input.duration_sec = duration_sec;

      // Generate recurrence if required
      if (state.assignment.conditions && state.assignment.conditions.parts) {
        input.recurrence = {
          parts: {
            freq: state.assignment.conditions.parts.freq,
            interval: state.assignment.conditions.parts.interval,
            byday: state.assignment.conditions.parts.byday,
          },
        };
      }

      return input;
    },
  },
  actions: {
    async setMeta({ commit }, value) {
      commit(MUTATE_WIZARD_META, value);
    },
    async setProgress({ commit }, value) {
      commit(MUTATE_WIZARD_PROGRESS, value);
    },
    async setBreadcrumbs({ commit }, value) {
      commit(MUTATE_WIZARD_PROGRESS, value);
    },
    async setLoading({ commit }, value) {
      commit(MUTATE_WIZARD_LOADING, value);
    },
    async setAssignment({ commit }, value) {
      commit(MUTATE_WIZARD_ASSIGNMENT, value);
    },
    async setConditions({ commit }, value) {
      commit(MUTATE_WIZARD_CONDITIONS, value);
    },
    async setConflicts({ commit }, value) {
      commit(MUTATE_WIZARD_CONFLICTS, value);
    },
  },
};
