import Vue from "vue";
import App from "./App.vue";
import OptixUIPlugin from "optixapp-ui-kit/src/vue-plugin";
import "optixapp-ui-kit/src/vue-base.css";
import "optixapp-ui-kit/src/icons";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import "./validation";
import "./utils/directives/currency";
import "./utils/directives/maxCharacters";
import "./utils/directives/visible";
import "./assets/styles/app.scss";
import PortalVue from "portal-vue";
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import heatmap from "highcharts/modules/heatmap";
import PosthogPlugin from "./plugins/posthog";
import VueForceNextTick from "vue-force-next-tick";
import VueGtag from "vue-gtag";
import { GesturePlugin } from "@vueuse/gesture";

exporting(Highcharts);
exportData(Highcharts);
heatmap(Highcharts);
Vue.config.productionTip = false;
Vue.use(VueForceNextTick);
Vue.use(OptixUIPlugin, {
  themed: true, // Create stylesheets and support theming functions
});
Vue.use(PortalVue);

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GOOGLE_TRACKING_ID },
  },
  router
);

Vue.use(GesturePlugin);

Vue.use(PosthogPlugin);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
